import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"

const SideContent = () => (
  <div className="side">
    <h2>Lectures and Lessons</h2>
    <p>
      Learn to build software with course material that is simple, stimulating,
      and entertaining enough to keep you engaged, no matter how technically
      challenged you think you are.
    </p>

    <p>
      Don’t waste your time figuring out what to learn! Traverse down distinct,
      carefully crafted plans with one-on-one sessions designed to match up to
      your goals and strengths.
    </p>
  </div>
)

const PageContent = () => (
  <div className="pageContent">
    <h2>Analyze</h2>
    <p>
      Listen, understand and represent the needs and structure of your projects
      and products.
    </p>
    <h2>Design</h2>
    <p>
      Match beauty to constraints to build simple, beautiful, and approachable
      products.
    </p>
    <h2>Develop</h2>
    <p>
      Learn the ways of programming and speak to computers in their native
      language.
    </p>
  </div>
)

const lecturePage = () => {
  return (
    <div className="projects lectures">
      <SubnavList />
      <TileList />
      <div className="contentArea">
        <PageContent />
        <SideContent />
      </div>
    </div>
  )
}

export default lecturePage;
